import React, {useState} from "react";
import PaymentEntry from "./PaymentEntry";
import api from "../util/api";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';


interface PaymentData {
    name: string;
    beginndatum: string;
    anmeldung_count: number;
    aussteller_id: string;
    preis_bezahlt: string;
    preis_gueltig: string;
    preis_kalkuliert: string;
    preis_kalkuliert_vorort: string;
    sum: string;
    price: string;
    payed: string;
    guthaben: string;
}

interface PostData {
    'orderDescription': string,
    'contests': string,
    'currency': string,
    'amount': number,
    'user': number,
    'language': string,
}

const PaymentInformation = (props) => {
    const [ data, setData] = useState([] as PaymentData[]);
    const [ postData, setPostData] = useState({
        'orderDescription': '',
        'contests': '',
        'currency': '',
        'amount': 0,
        'user': 0,
        'language': 'de',
    } as PostData);

    const { t } = useTranslation();


    const loadData = async () => {
        const responsePaymentData: { data: PaymentData[]} = await api().get(`user/zahlungen/list`);
        let data = responsePaymentData.data;

        data.map((item, idx) => {
            if (item.beginndatum) {
                item.beginndatum = (new Date(item.beginndatum)).toLocaleDateString('de');
            }
        });

        setData(data);

        const responsePostData: { data: PostData} = await api().get(`payment-prepare`);
        setPostData(responsePostData.data);
    };

    React.useEffect(() => {
        loadData();
    }, []);

    let totalPrice: number = 0;

    data.map((item, idx) => {
        totalPrice += parseInt(item.sum);
        return item;
    });

    return <div className="payments">
        <div className="payments__list">
            {data.map((item, idx) => {
                return <PaymentEntry key={idx} data={item}/>
            })}
        </div>
        <div className="payments__total">
            <div className="payments__total-label">
                { t("payment_total") }
            </div>
            <div className="text-right payment-price">
                € {totalPrice.toFixed(2)}
            </div>
        </div>
        <form action="https://zahlung.oekv.at/onlinezahlung.php" method="POST">
            <input type={"hidden"} name="orderDescription" value={postData.orderDescription} />
            <input type={"hidden"} name="contests" value={postData.contests} />
            <input type={"hidden"} name="currency" value={postData.currency} />
            <input type={"hidden"} name="amount" value={postData.amount} />
            <input type={"hidden"} name="userid" value={postData.user} />
            <input type={"hidden"} name="language" value={i18n.language} />

            <div className="payments__button-wrapper text-end">
                <button
                type="submit"
                disabled={totalPrice == 0 || totalPrice < 0 }
                className="btn btn--biggest btn--primary btn--add"
                >{t ("payment_button") }</button>
            </div>
        </form>

    </div>
};

export default PaymentInformation;
